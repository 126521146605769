<template>
    <div>
        <div class="row mt-2" style="background-color: white; padding: 10px">
           
            <div class="col-md-8">
                 <h5>Activity Details</h5>
                <table class="table table-sm ">
                    <tr>
                        <th>Assignment Level</th>
                        <td>{{activitiesDetails.assignment_level }}</td>
                    </tr>
                    <tr>
                        <th>Activity Type</th>
                        <td>{{activitiesDetails._activityTypeID.name }}</td>
                    </tr>
                    <tr>
                        <th>Project Name</th>
                        <td>{{activitiesDetails._project_id.name }}</td>
                    </tr>
                     <tr>
                        <th>Zone Name</th>
                        <td v-if="activitiesDetails._zone_id">{{activitiesDetails._zone_id.code }}</td>
                        <td v-else></td>
                    </tr>
                      <tr>
                        <th>Plot Name</th>
                        <td v-if="activitiesDetails._plot_id">{{activitiesDetails._plot_id.code }}</td>
                        <td v-else></td>
                    </tr>
                  
                    <tr>
                        <th>Description</th>
                        <td>{{activitiesDetails._activityTypeID.name }}</td>
                    </tr>
                     <tr>
                        <th>Start Date</th>
                        <td>{{activitiesDetails.start_date | moment("ddd,Do MMM  YYYY") }}</td>
                    </tr>
                     <tr>
                        <th>End Date</th>
                        <td>{{activitiesDetails.end_date | moment("ddd,Do MMM  YYYY") }}</td>
                    </tr>
                     <tr>
                        <th>Frequency</th>
                        <td>{{activitiesDetails.frequency }}</td>
                    </tr>
                     <tr>
                        <th>No of Worker</th>
                        <td>{{activitiesDetails.no_of_worker }}</td>
                    </tr>
                     <tr>
                        <th>Resources</th>
                        <td>{{activitiesDetails.resources }}</td>
                    </tr>
                       <tr>
                        <th>Status</th>
                        <td v-if = 'activitiesDetails.status == 1'> Pending</td>
                         <td v-if = 'activitiesDetails.status == 2'> In Progress</td>
                          <td v-if = 'activitiesDetails.status == 3'> Completed</td>
                    </tr>

                    <tr>
                        <th>Activity</th>
                        <td>{{activitiesDetails.progress }} %</td>
                    </tr>


                   
                   
                </table>
            </div>
            <div class="col-md-4 text-right">
               <img :src="activitiesDetails.image" class="img-fluid" alt="" srcset="">
            </div>
            
            
        </div>

    </div>
</template>
<script>
export default {
    data(){
        return {
            activitiesDetails: false
        }
    },
    mounted(){
        this.getOrgDetails();
    },
    methods:{
        getOrgDetails(){
           let id = this.$route.params.activity_id;
            window.axios.get("activity/"+id,)
            .then((resp) => {
                console.log(resp);
                this.activitiesDetails= resp.data.data;
            });
        }
    }
}
</script>
